.weatherBlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding-bottom: 35px;
  background-color: rgba(255, 255, 255, 0.8);
}
.blockColumn {
  display: flex;
  flex-direction: column;
}
.imgBlock {
  width: fit-content;
}
.weatherIcon {
  width: 100px;
  height: 100px;
}

.temperature {
  font-size: 25px;
  font-weight: bold;
}

.condition {
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.details {
  display: flex;
  width: 100%;
}

.detailItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detailTitle {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.detailValue {
  font-size: 14px;
  font-weight: bold;
}

.carousel .slide {
  background: none;
}

.carousel .control-arrow {
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 24px);
  cursor: pointer;
  z-index: 2;
}

.carousel .control-prev.control-arrow {
  left: 20px;
}

.carousel .control-next.control-arrow {
  right: 20px;
}

.carousel .control-dots {
  position: absolute;
  bottom: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 10px;
}

.carousel .control-dots .dot {
  background-color: rgba(0, 0, 0, 0.3);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.carousel .control-dots .dot.selected {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel .slide {
  background: none;
}

.carousel .control-arrow {
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  position: absolute;

  top: calc(50% - 24px);
  cursor: pointer;
  z-index: 2;
}

.carousel .control-prev.control-arrow {
  left: 20px;
}

.carousel .control-next.control-arrow {
  right: 20px;
}

.carousel .control-dots {
  position: absolute;
  bottom: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 10px 0;
}

.carousel .control-dots .dot {
  background-color: rgba(0, 0, 0, 0.3);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.carousel .control-dots .dot.selected {
  background-color: rgba(0, 0, 0, 0.8);
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.logo {
  width: 50%;
  max-height: 20px;
  object-fit: contain;
}

.indicator {
  padding: 0px 10px;
  cursor: pointer;
}

.indicatorActive {
  padding: 0px 10px;
  cursor: pointer;
  color: blue;
}
