.app-sidepanel {
  position: fixed;
  min-height: 100vh;
  height: 100%;
  left: 0;
  top: 0;
  font-size: 0.875rem;
  border-right: 1px solid $theme-border-color;
  .panel-drop {
    display: none;
  }
  .sidepanel-inner {
    position: relative;
    z-index: 10;
    background: #fff;
    @include box-shadow(rgba(0, 0, 0, 0.05) 0px 8px 15px);
    width: 250px;
    height: 100%;
  }

  .sidepanel-close {
    display: none;
    position: absolute;
    z-index: 20;
    right: -2rem;
    top: 0;
    color: #fff;
    font-size: 2rem;
  }

  &.sidepanel-hidden {
    left: -400px; //value >= 250px (sidepanel width + close button width)
  }
  &.sidepanel-visible {
    left: 0;
  }

  .app-nav-main {
    overflow-y: auto;
    /* Forefox scrollbar */
    scrollbar-color: rgba(0, 0, 0, 0.1) $theme-border-color;
    scrollbar-width: thin;

    /* Chrome webkit scrollbar */

    &::-webkit-scrollbar {
      width: 6px;
      background-color: $theme-border-color;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      @include border-radius(0.5rem);
    }
  }
}

.app-branding {
  height: 40px; //same as .app-header
  padding-top: 1rem;
  padding-left: 1rem;
  margin-bottom: 1rem;

  .logo-icon {
    width: 36px;
    height: 36px;
  }

  .logo-text {
    color: $theme-text-color-primary;
    font-size: 1.25rem;
    font-weight: 600;
    vertical-align: middle;
  }
}

.app-nav {
  color: $theme-text-color-secondary;
  display: block;
  padding: 0;
  width: 100%;
  .app-menu {
    margin-bottom: 0;
  }
  .nav-item {
    display: block;
    //  color: $theme-text-color-secondary;
    //	background: #fff;
    color: #adb5bd;

    background-color: #1d2939;
  }
  .nav-link {
    display: block;
    padding: 0.875rem 1rem;
    //color: $theme-text-color-primary;
    color: #adb5bd;
    position: relative;
    display: block;
    padding-left: 3rem;
    border-left: 3px solid transparent;
    &.active {
      color: #fff;
      background: #000;
      //color: $theme-color-primary;
      //background: lighten($theme-color-primary, 60%);
      border-left: 3px solid $theme-color-primary;
      font-weight: 500;
    }

    &.submenu-toggle.active {
      background: none;
      border-color: transparent;
    }

    &:hover {
      //color: $theme-color-primary;
      color: #fff;
    }
  }
  .nav-icon {
    position: absolute;
    left: 1rem;
    top: 0.7rem;
    .bi {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .submenu-arrow {
    position: absolute;
    right: 1rem;
    top: 0.7rem;
    //color: $theme-text-color-light;
    color: #adb5bd;
    svg {
      width: 14px;
      height: 14px;
    }
  }

  .submenu-toggle {
    @include transition(all 0.4s ease-in-out);
    &[aria-expanded="true"] {
      .submenu-arrow {
        color: $theme-text-color-primary;
        svg {
          -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }
    }
  }

  .submenu {
  }

  .submenu-item {
    margin-bottom: 0;
  }

  .submenu-link {
    //color: $theme-text-color-secondary;
    color: #adb5bd;
    font-size: 0.875rem;
    margin-left: 2rem;
    padding: 0.5rem 1rem;
    padding-left: 1rem;
    position: relative;
    display: block;
    margin-bottom: 0;

    &:before {
      background-color: $theme-border-color;
      content: " ";
      display: inline-block;
      height: inherit;
      left: 0;
      position: absolute;
      width: 2px;
      height: 100%;
      margin-top: -0.5rem;
    }

    &:hover {
      //color: $theme-text-color-primary;
      color: #fff;
    }

    &.active {
      //color: $theme-color-primary;
      color: #fff;
      &:before {
        background-color: $theme-color-primary;
      }
    }
  }
}

.app-sidepanel-footer {
  //position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-top: 1px solid $theme-border-color;
  background: $theme-bg-light;
  .app-nav-footer {
    margin-bottom: 0;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .app-sidepanel {
    @include transition(all 0.4s ease-in-out);
    left: -400px; //value >= 250px (sidepanel width + close button width)
    &.sidepanel-visible {
      display: block;
    }
  }
  .sidepanel-visible .sidepanel-close {
    display: inline-block; //prevent showing the panel close button in xl screen
  }
  .sidepanel-visible .sidepanel-drop {
    position: fixed;
    display: block;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    min-width: 100vw;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.35);
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .app-wrapper {
    margin-left: 250px;
  }
}
